import { Box, Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import RouterIcon from "@material-ui/icons/Router";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainGrid: {
      padding: theme.spacing(1, 2, 0, 2),
    },
  })
);
// @ts-ignore
export interface NetworkingDashboardProps {}

const text = {
  title: "Networking",
  buttonText: "View ACI Fabric",
  link: "https://sandboxapicdc.cisco.com/#bTenants:ACME%7Cuni/tn-ACME",
};

export const NetworkingDashboard: FunctionComponent<NetworkingDashboardProps> = () => {
  const classes = useStyles();

  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "tenant-diagram.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 1070, height: 1250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const handleClick = (url: string) => {
    const win = window.open(url, "_blank");
    win && win.focus();
  };

  return (
    <Box flex={1} className={classes.mainGrid}>
      <Grid container direction={"row"} justify={"space-between"}>
        <div>
          <Grid container direction={"row"} spacing={1}>
            <Grid item>
              <RouterIcon />
            </Grid>
            <Grid item>
              <Typography color="secondary" variant="h5">
                {text.title}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Button variant={"outlined"} onClick={() => handleClick(text.link)}>
          {text.buttonText}
        </Button>
      </Grid>
      <Grid container justify={"center"}>
        {data?.file.childImageSharp.fixed && (
          <Img fixed={data?.file.childImageSharp.fixed} />
        )}
      </Grid>
    </Box>
  );
};

export default NetworkingDashboard;
