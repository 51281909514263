import React from "react";
import { useTranslation } from "react-i18next";

import Layout from "../../components/layout/Layout";
import SEO from "../../components/seo/SEO";
import NetworkingDashboard from "../../components/networking-dashboard";

const NetworkingPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("networking.title")} />
      <NetworkingDashboard />
    </Layout>
  );
};

export default NetworkingPage;
